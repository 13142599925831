import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
	{
	  path: '/',
	  name: 'index',
    component: ()=>import('@/pages/index/index.vue'),
	},
  {
    path: "/",
    name: "index",
    component: () => import("@/pages/index/index.vue"),
  },
  {

    path: "/about",
    name: "about",
    component: () => import("@/pages/pc/about.vue"),
  },
  {
	  path: '/product',
	  name: 'product',
    component: ()=>import('@/pages/pc/product.vue'),
	},
  {
    path: '/kic',
    name: 'kic',
    component: ()=>import('@/pages/pc/kic.vue'),
  },
  {
	  path: '/innovationArea',
	  name: 'innovationArea',
    component: ()=>import('@/pages/pc/innovationArea.vue'),
	},

  {
    path: "/news",
    name: "news",
    component: () => import("@/pages/pc/news.vue"),
  },
  {
    path: "/contact",
    name: "contact",
    component: () => import("@/pages/pc/contact.vue"),
  },
  {
    path: "/job",
    name: "job",
    component: () => import("@/pages/pc/job.vue"),
  },
  {
    path: "/wapabout/:id",
    name: "wapabout",
    component: () => import("@/pages/wap/about.vue"),
  },
  {
    path: "/wapinnovate/:id",
    name: "wapinnovate",
    component: () => import("@/pages/wap/innovate.vue"),
  },
  {
    path: "/wapkic",
    name: "wapkic",
    component: () => import("@/pages/wap/kic.vue"),
  },
  {
    path: "/wapnews",
    name: "wapnews",
    component: () => import("@/pages/wap/news.vue"),
  },
  {
    path: "/wapcontact",
    name: "wapcontact",
    component: () => import("@/pages/wap/contact.vue"),
  },
  {
    path: "/wapproduct",
    name: "wapproduct",
    component: () => import("@/pages/wap/product.vue"),
  },
  {
    path: "/wapnewsDetail",
    name: "wapnewsDetail",
    component: () => import("@/pages/wap/newsDetail.vue"),
  },
  {
    path: "/wapjob",
    name: "wapjob",
    component: () => import("@/pages/wap/job.vue"),
  },
];

const router = new VueRouter({
  mode: "hash",
  base: process.env.BASE_URL,
  routes,
});

export default router;
