import axios from 'axios'
import qs from 'qs';
// import { resolve } from 'q'
// import { Toast } from 'vant'
axios.defaults.baseURL = 'https://hn-admin.hansn.cn/'
axios.defaults.timeout = 5000
export function baseURL(){
    return axios.defaults.baseURL
}
export function get(url,data){
    return new Promise((resolve,reject)=>{
        axios({
            url:url,
            method:'GET',
            params:data
        }).then(res=>{
            resolve(res)
        }).catch(err=>alert('网络错误请联系管理员'))
    })
}

export function post(url,data){
    return new Promise((resolve,reject)=>{
        axios({
            url:url,
            method:'POST',
            data:qs.stringify(data)
        }).then(res=>{
            resolve(res)
        }).catch(err=>alert('网络错误请联系管理员'))
    })
}
