<template>
  <div class="header" @mouseover="move" @mouseout="out">
    <div class="head-bg" :class="widthactive ? 'headBgb' : ''" ></div>
    <div
      class="w1600 flex-row justify-between items-center pr pd30"
      style="z-index: 999"

    >
      <img
        :src="
          widthactive
            ? require('../assets/img/head-logo-b.png')
            : require('../assets/img/head-logo-w.png')
        "
        alt=""
        class="head-logo cur"
        @click="router('/')"

      />
      <div
        class="head-content flex-row justify-between items-center"
        :class="widthactive ? 'fontColorB' : ''"

      >
        <div
          class="head-item head-item-w cur"
          v-for="(item, index) in headNav"
          :key="index"
          @click="router(item.url)"
        >
          {{ item.name }}
        </div>
        <div class="language">
          <span>中文</span><span class="fengexian">|</span><span class="lan-en">EN</span>
        </div>
      </div>
    </div>
    <div class="head-submenu flex-row" v-show="isshow">
      <div class="w1600 flex-row">
        <div class="head-content flex-row justify-between">
        <div class="head-item head-submenu-item">
          <div class="head-nav" @click="router('/about?nav=')">集团简介</div>
          <div class="head-nav" @click="router('/about?nav=jiagou')">集团架构</div>
          <div class="head-nav" @click="router('/about?nav=lichen')">发展历程</div>
          <div class="head-nav" @click="router('/about?nav=huoban')">合作伙伴</div>
          <div class="head-nav" @click="router('/about?nav=rongyu')">企业荣誉</div>
        </div>
        <div class="head-item head-submenu-item">
          <div class="head-nav" @click="router('/product')">耐火材料</div>
          <div class="head-nav" @click="router('/kic')">创智天地</div>
        </div>
        <div class="head-item head-submenu-item">
          <div class="head-nav" @click="router('/innovationArea?nav=jishu')">技术中心</div>
          <div class="head-nav" @click="router('/innovationArea?nav=pingzhi')">品质保障</div>
          <div class="head-nav" @click="router('/innovationArea?nav=fazhan')">可持续发展</div>
        </div>
        <div class="head-item head-submenu-item">
          <div class="head-nav" @click="router('/news?type=1')">公司新闻</div>
          <div class="head-nav" @click="router('/news?type=3')">企业公告</div>
          <div class="head-nav" @click="router('/news?type=2')">展会分享</div>
        </div>
        <div class="head-item head-submenu-item">
          <div class="head-nav" @click="router('/job')">人才招聘</div>
          <div class="head-nav" @click="router('/contact')">联系方式</div>
        </div>
        <div class="head-item head-submenu-item">
          <div class="head-nav"></div>
          <div class="head-nav"></div>
        </div>
      </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      headNav: [
        {
          name: "关于恒耐",
          url: '/about?nav=',
        },
        {
          name: "恒耐产业",
          url: '/product',
        },
        {
          name: "创新领域",
          url: '/innovationArea',
        },
        {
          name: "新闻中心",
          url: '/news',
        },
        {
          name: "联系我们",
          url: '/contact',
        },
      ],
      widthactive: false,
      isshow:false
    };
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
  },
  methods: {
    move(){
      this.isshow=true;
      this.widthactive=true;
    },
    out(){
      this.isshow=false;
      if (window.pageYOffset > 100) {
        this.widthactive = true;
      } else {
        this.widthactive = false;
      }
    },
    handleScroll() {
      if (window.pageYOffset > 100) {
        this.widthactive = true;
      } else {
        this.widthactive = false;
      }
    },
    router(url){
      this.$router.push(url)
    }
  },
};
</script>
<style scoped>
.header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 999;

}
.head-bg {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 0;
  z-index: 99;
  pointer-events: none;
  /* transform: translateY(-120%);
  transition: all 0.5s; */
}
.headBgb {
  height: 100% !important;
  background: #fff;
  animation: headani 0.5s;
  /* transition: all 0.5s; */
}
@keyframes headani {
  0% {
    opacity: 0;
    transform: translateY(-120%);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
.head-logo {
  width: 222px;
  height: 62px;
}
.head-content {
  width: 1100px;
  color: #fff;
}
.pd30 {
  padding: 19px 0;
}
.head-item {
  width: 200px;
  white-space: nowrap;
  font-size: 16px;
  font-weight: 500;
}
.fontColorB {
  color: #000 !important;
}
.head-submenu {
  /* box-shadow: 0px 2px 3px #DBDBDB; */
  position: relative;
  z-index: 999;
}
.head-submenu>div{
   justify-content: flex-end;
}
.head-submenu-item {
  margin-bottom: 16px;

}
.head-nav{
  width: max-content;
  height: 30px;
  color: #000;
  cursor: pointer;
  margin-bottom: 8px;
  font-size: 16px;
  font-weight: 500;
}
.head-nav:hover{
  color: #E83C27;
  /* animation: navline .5s;
  white-space: nowrap;
  border-bottom: 2px solid #E83C27; */
}
@keyframes navline{
  from { width: 0; }
  to { width: 100%; }
}
.language{
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 200px;
  margin-left: -100px;
  font-size: 16px;
}
.lan-en{
  color: #C7C7C7;
}
.fengexian{
  margin: 0 8px;
  color: #C7C7C7;;
}
</style>
