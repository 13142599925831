import Vue from 'vue';
import App from './app.vue';
import router from './router';
// import Vheader from './components/vheader/vheader.vue';
import ModalWizard from 'vue-modal-wizard';
import CollapseTransition from './collapse-transition.js';
import VueAMap from "vue-amap";
import AOS from 'aos';
import {get,post,baseURL} from '../src/api.js';
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';

import Vant from 'vant';
import 'vant/lib/index.css';
Vue.use(ElementUI);
Vue.use(Vant);
Vue.prototype.$get  = get;
Vue.prototype.$post = post;
Vue.prototype.$baseURL = baseURL;
Vue.use(ModalWizard)
Vue.prototype.nav = (url) => {
  //window.location.href=url
  router.push({path: url})
}
Vue.config.productionTip = false;
Vue.use(AOS);
Vue.use(VueAMap);

// Vue.component("Vheader", Vheader)
Vue.mixin({
  components: {
    'collapse-transition': CollapseTransition,
  },
  data() {
    return {cpshow: true}
  },
  mounted(){
    AOS.init({
      offset: 0,
      duration: 600,
      delay: 600,
      once: true,
    })
  },
  methods: {
    goAnchors(id,pos) {
      this.$nextTick(() => {
        $(id).animate({
          scrollTop: document
            .getElementById(pos)
            .getBoundingClientRect()
            .top - 50
        }, 0);
      })
    },
    setcpshows() {
      this
        .$refs
        .cpnav
        .setcpshow()
    }
  }
})


import Pcheader from './components/pcheader.vue';
import Pcheadern from './components/pcheadern.vue';
import Pcfooter from './components/pcfooter.vue';
import wapfooter from './components/wapfooter.vue';
import wapheader from './components/wapheader.vue';

import "./../node_modules/aos/dist/aos.css";
// 初始化vue-amap
VueAMap.initAMapApiLoader({
  // 高德的key
  key: "ddeb72a5aa5c40c06934e5d6f3a0e0a5",  // 换成你自己的key,这是我随便写的
  // 插件集合
  plugin: [
    "AMap.Autocomplete",
    "AMap.PlaceSearch",
    "AMap.Scale",
    "AMap.OverView",
    "AMap.ToolBar",
    "AMap.MapType",
    "AMap.PolyEditor",
    "AMap.CircleEditor"
  ],
  // 高德 sdk 版本，默认为 1.4.4
  v: "1.4.4",
})
Vue.component("Pcheader",Pcheader)
Vue.component("Pcheadern",Pcheadern)
Vue.component("Pcfooter",Pcfooter)
Vue.component("wapfooter",wapfooter)
Vue.component("wapheader",wapheader)
new Vue({
  router,
  render: (h) => h(App)
}).$mount('#app');
router.afterEach((to,from,next) => {
   window.scrollTo(0,0);
});

