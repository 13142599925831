<template>
  <div class="header">
    <div class="header-top flex-row justify-between items-center">
      <img
        src="../assets/wapImg/head-logo.png"
        alt=""
        class="head-logo"
        @click="router('/')"
      />
      <div class="header-line flex-row items-center" @click="showHead">
        <div class="header-lang">EN</div>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          v-if="!ishead"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          fill="none"
          version="1.1"
          width="45"
          height="45"
          viewBox="0 0 45 45"
        >
          <g>
            <g>
              <g>
                <g>
                  <g>
                    <rect
                      x="6"
                      y="6"
                      width="32"
                      height="2.461538553237915"
                      rx="0"
                      fill="#1A5697"
                      fill-opacity="1"
                    />
                  </g>
                  <g>
                    <rect
                      x="6"
                      y="20.769206047058105"
                      width="32"
                      height="2.461538553237915"
                      rx="0"
                      fill="#1A5697"
                      fill-opacity="1"
                    />
                  </g>
                  <g>
                    <rect
                      x="6"
                      y="35.538461685180664"
                      width="32"
                      height="2.461538553237915"
                      rx="0"
                      fill="#1A5697"
                      fill-opacity="1"
                    />
                  </g>
                </g>
              </g>
            </g>
            <g></g>
          </g>
        </svg>
        <!-- <img
          src="../assets/wapImg/head-menu.png"
          alt=""
          class="head-menu"
          v-if="!ishead"
        /> -->
        <img
          src="../assets/wapImg/head-menu-x.png"
          alt=""
          class="head-menu"
          v-if="ishead"
        />
      </div>
    </div>
    <collapse-transition>
      <div class="header-content" v-if="ishead">
        <div class="header-item" v-for="(item, index) in headNav" :key="index">
          <div
            class="header-item-name flex-row justify-between items-center"
            @click="chooseNav(index, item)"
          >
            <div class="" :class="he == index ? 'colorB' : ''">
              {{ item.name }}
            </div>
            <img
              src="../assets/wapImg/head-bottom.png"
              alt=""
              class="head-icon"
              v-if="he == index"
            />
            <img
              src="../assets/wapImg/head-right.png"
              alt=""
              class="head-icon"
              v-else
            />
          </div>
          <div class="header-list" v-if="item.arr && he == index">
            <div
              class="header-list-name"
              v-for="(it, i) in item.arr"
              :key="i"
              @click="router(it.url)"
            >
              {{ it.title }}
            </div>
          </div>
        </div>
      </div>
    </collapse-transition>
  </div>
</template>

<script>
export default {
  data() {
    return {
      ishead: false,
      headNav: [
        {
          name: "关于恒耐",
          url: "",
          arr: [
            {
              title: "集团简介",
              url: "/wapabout/0",
            },
            {
              title: "集团文化",
              url: "/wapabout/1",
            },
            {
              title: "集团架构",
              url: "/wapabout/2",
            },
            {
              title: "发展历程",
              url: "/wapabout/3",
            },
            {
              title: "合作伙伴",
              url: "/wapabout/4",
            },
            {
              title: "企业荣誉",
              url: "/wapabout/5",
            },
          ],
        },
        {
          name: "恒耐产业",
          url: "",
          arr: [
            {
              title: "耐火材料",
              url: "/wapproduct",
            },
            {
              title: "创智天地",
              url: "/wapkic",
            },
          ],
        },
        {
          name: "创新领域",
          url: "",
          arr: [
            {
              title: "技术中心",
              url: "/wapinnovate/0",
            },
            {
              title: "专利证书",
              url: "/wapinnovate/1",
            },
            {
              title: "品质保障",
              url: "/wapinnovate/2",
            },
            {
              title: "贯彻可持续发展战略",
              url: "/wapinnovate/3",
            },
          ],
        },
        {
          name: "新闻中心",
          url: "",
          arr: [
            {
              title: "公司新闻",
              url: "/wapnews?type=1",
            },
            {
              title: "企业公告",
              url: "/wapnews?type=3",
            },
            {
              title: "展会分享",
              url: "/wapnews?type=2",
            }
          ],
        },
        {
          name: "联系我们",
          url: "/wapcontact",
          arr: [
            {
              title: "人才招聘",
              url: "/wapjob",
            },
            {
              title: "联系方式",
              url: "/wapcontact",
            },
          ],
        },
      ],
      he: null,
    };
  },
  methods: {
    chooseNav(index, item) {
      if (item.arr.length > 0) {
        if (this.he == index) {
          this.he = null;
        } else {
          this.he = index;
        }
      } else {
        this.$router.push(item.url);
      }
    },
    router(url) {
      this.$router.push(url);
    },
    showHead() {
      this.ishead = !this.ishead;
      this.he = null;
    },
  },
};
</script>

<style scoped>
.header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 999;
  background: #fff;
}
.header-top {
  padding: 20px 40px;
}
.head-logo {
  width: 222px;
  height: 62px;
}
.head-menu {
  width: 45px;
  height: 45px;
}
.header-content {
  background: #fff;
  width: 100%;
  transition: all 0.5s;
  border-top: 2px solid #d8d8d8;
  height: calc(100vh - 102px);
  /* height: 0;
  opacity: 0;
  animation: headin 0.5s forwards; */
}
@keyframes headin {
  0% {
    height: 0;
    opacity: 0;
  }
  100% {
    height: calc(100vh - 102px);
    opacity: 1;
  }
}
.headin {
}
.header-item {
  border-bottom: 2px solid #d8d8d8;
}
.header-item-name {
  padding: 36px 40px;
  font-size: 30px;
  font-weight: 500;
  line-height: 40px;
  color: rgba(0, 0, 0, 0.8);
  border-bottom: 1px solid #eeeeee;
}

.head-icon {
  width: 30px;
  height: 30px;
}
.header-list-name {
  padding: 36px 40px;
  font-size: 28px;
  line-height: 40px;
  color: rgba(0, 0, 0, 0.8);
  border-bottom: 1px solid #eeeeee;
}
.colorB {
  color: #1a5697 !important;
}
.header-lang {
  font-size: 30px;
  line-height: 45px;
  color: #1a5697;
  margin-right: 30px;
}
.header-line svg {
  width: 45px;
  height: 45px;
}
</style>
