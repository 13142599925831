<template>
  <div class="footer">
    <div class="foot-top">
      <div class="foot-top-content w1600">
        <img class="foot-logo" src="../../src/assets/img/foot-logo.png" alt="" />
        <div class="foot-top-right" @mouseenter="WxCodeMouseEnter" @mouseleave="WxCodeMouseLeave">
          <div class="foot-text1">关注恒耐集团</div>
          <img class="wechat-icon" :src="wechatImg" alt="" />
        </div>
      </div>
    </div>
    <div class="foot-center">
      <div class="foot-center-content w1600">
        <div class="foot-menu flex-row justify-between">
          <div v-for="(item, index) in footerNav" :key="index">
            <div class="footer-name" @click="router(item.href)">{{ item.name }}</div>
            <div class="footer-item">
              <div class="footer-stitle" v-for="(it, i) in item.arr" :key="i" @click="router(it.href)">
                {{ it.title }}
              </div>
            </div>
          </div>
        </div>
        <div class="foot-center-info">
          <img v-show="wxCode" src="../assets/img/wx-code2.jpg" alt="" class="footer-ewm" />
          <div class="foot-text3">中国 · 江苏省 · 常州市武进区横山桥镇省庄</div>
          <div class="foot-text4">T: 0086-519-8860 1747</div>
          <div class="foot-text5">E: hengnai@126.com</div>
        </div>
      </div>
    </div>
    <div class="foot-bottom">
      <div class="foot-bottom-content w1600">
        <div class="foot-text2">Copyright © 2024 <span class="foot-jshn">江苏恒耐炉料集团有限公司 版权所有   苏ICP备18030512号-1</span></div>
        <img @click="toHttp('http://www.hansn.cn')" src="../../src/assets/img/hansn.png" alt="" class="hansn-text" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      wxCode: false,
      wechatImg: require('../../src/assets/img/wechat-icon.png'),
      footerNav: [
        {
          name: "关于恒耐",
          href: '/about?nav=',
          arr: [
            {
              title: "集团简介",
              href: "/about",
            },
            {
              title: "集团架构",
              href: "/about?nav=jiagou",
            },
            {
              title: "发展历程",
              href: "/about?nav=lichen",
            },
            {
              title: "合作伙伴",
              href: "/about?nav=huoban",
            },
            {
              title: "企业荣誉",
              href: "/about?nav=rongyu",
            },
          ],
        },
        {
          name: "恒耐产业",
          href: '/product',
          arr: [
            {
              title: "耐火材料",
              href: "/product",
            },
            {
              title: "创智天地",
              href: "/kic",
            },
          ],
        },
        {
          name: "创新领域",
          href: '/innovationArea',
          arr: [
            {
              title: "技术中心",
              href: "/innovationArea?nav=jishu",
            },
            {
              title: "品质保障",
              href: "/innovationArea?nav=pingzhi",
            },
            {
              title: "可持续发展",
              href: "/innovationArea?nav=fazhan",
            },
          ],
        },
        {
          name: "新闻中心",
          href: '/news',
          arr: [
            {
              title: "公司新闻",
              href: "/news?type=1",
            },
            {
              title: "企业公告",
              href: "/news?type=3",
            },
            {
              title: "展会分享",
              href: "/news?type=2",
            },
          ],
        },
        {
          name: "联系我们",
          href: '/contact',
          arr: [
            {
              title: "人才招聘",
              href: "/job",
            },
            {
              title: "联系方式",
              href: "/contact",
            },
          ],
        },
      ],
    };
  },
  methods: {
    router(url){
      this.$router.push(url)
    },
    WxCodeMouseEnter(){
      this.wxCode = true;
      this.wechatImg = require('../../src/assets/img/green-wechat-icon.png');
    },
    WxCodeMouseLeave(){
      this.wxCode = false;
      this.wechatImg = require('../../src/assets/img/wechat-icon.png');
    },
    toHttp(url){
      window.open(url);
    }
  },
};
</script>

<style scoped>
.footer {
  background: #f4f4f4;
}
.footer-name {
  cursor: pointer;
  font-size: 14px;
  font-weight: 600;
}
.footer-item {
  margin-top: 5px;
}
.footer-stitle {
  font-size: 14px;
  line-height: 32px;
  color: rgba(0, 0, 0, 0.5);
}
.footer-stitle:hover {
  cursor: pointer;
  color: #E83C27 !important;
}

.foot-top{
  height: 80px;
  background: #1A5697;
}
.foot-top-content{
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.foot-logo{
  width: 176px;
  height: 50px;
}
.foot-text1{
  font-size: 14px;
  color: #FFFFFF;
  margin-right: 18px;
}
.wechat-icon{
  width: 26px;
  height: 21px;
}
.foot-top-right{
  display: flex;
  cursor: pointer;
}
.foot-bottom{
  height: 64px;
  background: #5B5B5B;
}
.foot-bottom-content{
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;
}
.foot-text2{
  font-weight: 400;
  font-size: 14px;
  color: #FFFFFF;
}
.foot-jshn{
  font-size: 12px;
}
.foot-center{
  height: 255px;
  background: url(../assets/img/foot-bg.png);
  background-size: cover;
  object-fit: cover;
}
.foot-menu{
  width: 716px;
  margin-top: 38px;
}
.foot-center-info{
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  font-family: GalanoGrotesque-Regular;
  font-weight: 400;
  font-size: 16px;
  color: #050505;
  margin-top: 42px;
}
.foot-center-content{
  display: flex;
  justify-content: space-between;
}
.footer-ewm{
  position: absolute;
  z-index: 999;
  width: 144px;
  height: 144px;
  margin-top: -270px;

}
.hansn-text{
  cursor: pointer;
}
</style>
