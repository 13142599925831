<template>
  <div id="app">
    <router-view :key="$route.fullPath" />
  </div>
</template>
<script>
export default {
  mounted() {

    // if (this._isMobile()) {
    //   this.nav('/')
    // } else {
    //   this.nav('/pcindex')
    // }
  },
  methods: {
    _isMobile() {
      let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
      return flag;
    }
  }
}
</script>
<style>
html {
  font-size: 100PX !important;
}
@media screen and (min-width: 850px) {
  body {
    letter-spacing: 2px !important;
  }
}
@media screen and (max-width: 1500px) {
  html {
    font-size: 80PX !important;
  }
}

@media screen and (max-width:1200px) {
  html {
    font-size: 70PX !important;
  }

}

@media screen and (max-width: 1100px) {
  html {
    font-size: 60PX !important;
  }
}

@media screen and (max-width: 1000px) {
  html {
    font-size: 13.333333333vw !important;
  }
}

@media screen and (max-width: 850px) {
  html {
    font-size: 13.333333333vw !important;
  }
}

* {
  text-underline-offset: 0.1rem;
  margin: 0;
  padding: 0;
}

.over100 {
  height: 100vh;
  over-flow: auto;
}

.sticky {
  position: sticky !important;
  top: .8rem;
  z-index: 90;
  background-color: #fff;
}

.fontblod,
.font-bold {
  font-weight: bold;
}

/************************************************************
  ** 请将全局样式拷贝到项目的全局 CSS 文件或者当前页面的顶部 **
  ** 否则页面将无法正常显示                                  **
  ************************************************************/


.swiper-horizontal>.swiper-pagination-bullets .swiper-pagination-bullet,
.swiper-pagination-horizontal.swiper-pagination-bullets .swiper-pagination-bullet {
  margin: 0 .2rem;
}

.Robotol {
  font-family: Robotol;
}

.swiper {
  width: 100%;
}

html {
  font-size: 13.3333333333vw;
}

body {
  margin: 0;
  font-family: "Microsoft Yahei", "PingFang SC" !important;
  overflow-x: hidden;
}

body * {
  box-sizing: border-box;
  flex-shrink: 0;
}
.pr{
  position: relative;
}
.pa{
  position: absolute;
}
#app {
  /* width: 100vw; */
  height: 100vh;
}
.w1600{
  width: 1600px;
  margin: 0 auto;
}
.flex-row {
  display: flex;
  flex-direction: row;
}

.flex-col {
  display: flex;
  flex-direction: column;
}

.justify-start {
  justify-content: flex-start;
}

.justify-end {
  justify-content: flex-end;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.justify-around {
  justify-content: space-around;
}

.justify-evenly {
  justify-content: space-evenly;
}

.items-start {
  align-items: flex-start;
}

.items-end {
  align-items: flex-end;
}

.items-center {
  align-items: center;
}

.items-baseline {
  align-items: baseline;
}

.items-stretch {
  align-items: stretch;
}

.self-start {
  align-self: flex-start;
}

.self-end {
  align-self: flex-end;
}

.self-center {
  align-self: center;
}

.self-baseline {
  align-self: baseline;
}

.self-stretch {
  align-self: stretch;
}

.flex-1 {
  flex: 1 1 0%;
}

.flex-auto {
  flex: 1 1 auto;
}

.grow {
  flex-grow: 1;
}

.grow-0 {
  flex-grow: 0;
}

.shrink {
  flex-shrink: 1;
}

.shrink-0 {
  flex-shrink: 0;
}

.relative {
  position: relative;
}

.cur {
  cursor: pointer;
}

.overh {
  overflow: hidden;
}



img {
  border: none;
  vertical-align: middle;
  display: block;
}

.roboto {
  font-family: Roboto;
  font-weight: bold;
}
a{
  text-decoration: none;
  color: #000;
}

@font-face {
  font-family: GalanoGrotesque-Regular;
  src: url("./font/Galano-Grotesque.ttf") format("opentype");
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
}
.pr{
  position: relative;
}
</style>

