<template>
  <div class="wapfooter">
    <div class="flex-row justify-between items-center wrap">
      <div
        class="wapfooter-item"
        v-for="(item, index) in wapfooter"
        :key="index"
        @click="router(item.url)"
      >
        {{ item.name }}
      </div>
    </div>
    <img
      src="../assets/wapImg/wx-icon.png"
      alt=""
      class="wx-icon"
      @click="showEwm"
    />
    <div class="wapfooter-info">
      服务热线: 0519-8861 0539<br />
      邮箱: hengnai@126.com<br />
      地址: 中国江苏省常州市武进区横山桥镇省庄
    </div>
    <div class="wapfooter-line"></div>
    <div class="wapfooter-copy">
      © 2024 版权所有:江苏恒耐炉料集团有限公司 版权所有<br />
      <a href="https://beian.miit.gov.cn/#/Integrated/index" target="_blank"
        >苏ICP备18030512号-1</a
      >
    </div>
    <div class="wapfooter-disgin">
      Designed by <a href="https://www.hansn.cn/"><span>HANSN</span>BRAND</a>
    </div>
    <!-- 二维码 -->
    <div class="ewm-banner flex-row justify-center items-center" v-if="isewm" @click="isewm=false">
      <img src="../assets/wapImg/footer-ewm.jpg" alt="" @click.stop="isewm=true" />
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      wapfooter: [
        {
          name: "关于恒耐",
          url: "/wapabout/0",
        },
        {
          name: "创新领域",
          url: "/wapinnovate/0",
        },
        {
          name: "耐火材料",
          url: "/wapproduct",
        },
        {
          name: "创智天地",
          url: "/wapkic",
        },
        {
          name: "新闻中心",
          url: "/wapnews",
        },
        {
          name: "联系我们",
          url: "/wapcontact",
        },
      ],
      isewm:false
    };
  },
  methods: {
    showEwm() {
      this.isewm=true;
    },
    router(url) {
      this.$router.push(url);
    },
  },
};
</script>

<style scoped>
.wapfooter {
  background: #eaeaea;
  padding: 70px 40px;
}
.wrap {
  flex-wrap: wrap;
}
.wapfooter-item {
  width: 320px;
  height: 82px;
  line-height: 82px;
  font-size: 24px;
  font-weight: 700;
  color: rgba(0, 0, 0, 0.6);
  border-bottom: 2px solid #d8d8d8;
}
.wapfooter-item:first-child {
  border-top: 2px solid #d8d8d8;
}
.wapfooter-item:nth-child(2) {
  border-top: 2px solid #d8d8d8;
}
.wx-icon {
  width: 70px;
  height: 56px;
  margin-top: 40px;
}
.wapfooter-info {
  margin-top: 22px;
  font-size: 30px;
  line-height: 1.6;
  color: #6c6c6c;
}
.wapfooter-line {
  margin: 15px 0 26px;
  width: 100%;
  height: 2px;
  background: #d8d8d8;
}
.wapfooter-copy {
  font-size: 24px;
  color: rgba(0, 0, 0, 0.3);
}
.wapfooter-copy a {
  color: rgba(0, 0, 0, 0.3);
}
.wapfooter-disgin {
  font-size: 24px;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.3);
}
.wapfooter-disgin a {
  color: rgba(0, 0, 0, 0.3);
}
.wapfooter-disgin span {
  font-weight: 700;
}
.ewm-banner {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 9999;
}
.ewm-banner img {
  width: 426px;
  height: 426px;
  /* height: 515px; */
  border-radius: 6px;
}
</style>
